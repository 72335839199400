import theme, { Theme } from "@chakra-ui/theme"
import { mode, Styles } from "@chakra-ui/theme-tools"

const styles: Styles = {
  ...theme.styles,
  global: props => ({
    ...theme.styles.global,
    fontWeight: "light",
    "html, body": {
      fontSize: "sm",
      color: "gray.600",
      lineHeight: "tall",
    },
  }),
}
// console.log(theme)

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    black: "#131217",
    header: "#49A197",
    navigation: "#F8F9FA",
    primary: "#55A8DC",
  },
  fonts: {
    ...theme.fonts,
    heading:
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    body:
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  config: {
    ...theme.config,
    useSystemColorMode: false,
    initialColorMode: "light",
  },
  styles: {
    global: {
      "html, body": {
        fontSize: "sm",
        color: "gray.600",
        lineHeight: "tall",
      },
      a: {
        color: "teal.500",
      },
    },
  },
}

// console.log(customTheme)

export default customTheme
